module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"batmaid","accessToken":"MC5ZUUV3R3hFQUFDUUFST0FI.Vx4bVu-_vXPvv73vv73vv73vv712L--_ve-_ve-_vSvvv71o77-9bRYVRAxfK--_vX4adO-_vTk"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://c2f61acafc7342d6bba3e6ccdb725276@sentry.io/1723021","environment":"staging","release":"140.0","enabled":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Batmaid","short_name":"Batmaid","start_url":"/","background_color":"#f2f2f2","theme_color":"#ffffff","display":"standalone","icon":"../@assets/images/favicon.png","legacy":true,"prefer_related_applications":true,"related_applications":[{"platform":"play","id":"com.batmaid.batmaid"}],"crossOrigin":"use-credentials","theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"f3c416edc462cb83ddbcf8a1351f6b2a"},
    },{
      plugin: require('../../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"custom":[{"name":"Fellix","file":"/assets/gtsb/fonts/fellix/all.css"}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
